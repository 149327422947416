import React from "react"
import styles from './styles.module.css'
import { navigate } from '@reach/router'

const ListItem = ({title, text, highlights, url, actions}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      {url && <a onClick={() => navigate(url)}><h3>{title}</h3></a>}
      {!url && <h3>{title}</h3>}
      <p>{text}</p>
    </div>
    <div className={styles.highlights}>
      {Object.keys(highlights).map((key, index) => (
        <div key={index}>
          <span>{key}</span>
          <span>{highlights[key]}</span>
        </div>
      ))}
      <div>
        {actions && actions.map((action, index) => (
          <button 
            key={index}
            onClick={action.callback}
          >
            {action.text}
          </button>
        ))}
      </div>
    </div>
  </div>
)
export default ListItem
