import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import moment from 'moment'
import ListItem from '../../listItem'
import { get, getAndConcat } from '../../../reducers/actions/project.js'
import styles from './styles.module.css'

const ProviderJobs = ({id, status}) => {
  const projects = useSelector(state => state.projectReducer.getProjects)
  const user = useSelector(state => state.userReducer.login.response)
  const dispatch = useDispatch()

  const [isFirstRender, setIsFirstRender] = useState(true)
  const queryParams = {
    providerId: id || user._id,
    page: 1,
    limit: 40
  }
  
  if (status) {
    queryParams.status = status
  }

  useEffect(() => {
    if(isFirstRender) {
      get(dispatch)(queryParams)
      setIsFirstRender(false)
    }
  })

  const loadDocuments = entry => {
    if (entry.intersectionRatio > 0) {
      if(
        !projects.loading &&
        projects.response &&
        projects.response.totalPages > projects.response.page
      ) {
        getAndConcat(dispatch)({...queryParams, page: projects.response.page+1})
        }
    }  
  }

  const observer =  new IntersectionObserver(entries => entries.forEach(loadDocuments))
  const scrollObserver = useCallback(node => observer.observe(node), getAndConcat(dispatch))
  const boundaryRef = useRef(null);
  
  useEffect(() => {
    if (boundaryRef.current) {
      scrollObserver(boundaryRef.current)
    }
  }, [projects, boundaryRef])

  const list = projects.response ? projects.response.docs.map(project => ({
    title: project.name,
    text: project.summary,
    highlights: {
      'Price': project.provider.proposal.price,
      'Estatus': project.status,
    },
    actions: [
      {text: 'editar', callback: () => navigate(`/new-project?id=${project._id}`)},
      {text: 'borrar', callback: () => {}}
     ]
  })): []

  const {page, limit} = projects.response ? projects.response : queryParams
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {list.map((project, index) => (
          <React.Fragment key={index}>
            <ListItem key={index} {...project} />
            {index === (page*limit/2) && <div ref={boundaryRef}></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default ProviderJobs
